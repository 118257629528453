<ng-template #termNameCompleteTemplate let-model="item" let-index="index">
  <div>{{model.matchName}} ({{model.termid}})
    <span *ngIf="model.isSynonymMatch" class="synonym-match">synonym</span>
  </div>
  <div *ngIf="model.definition" class="term-definition">
    {{model.definition}}
  </div>
</ng-template>

<input #termNameCompleteBox
       placeholder="{{placeholder}}"
       [(ngModel)]="selectedTerm"
       [typeahead]="dataSource"
       [typeaheadWaitMs]="50"
       [typeaheadMinLength]="2"
       [typeaheadItemTemplate]="termNameCompleteTemplate"
       [typeaheadOptionField]="'name'"
       (typeaheadOnSelect)="typeaheadOnSelect($event)"
       autocomplete="off"
       class="form-control search-box" style="display: inline;">
