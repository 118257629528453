<div class="recent-news">
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/PombeTalks32px.png"/>
<h4 id="next-pombetalks-september-18th"><strong>Next pombeTalks: September 18th</strong></h4>
<p><em>2024-09-11</em></p>
<p>The next <a href="https://evonuclab.org/pombetalks">pombeTalks</a> will take place on Wednesday, September 18th, at <a href="https://everytimezone.com/s/4009cf69">08:00</a> San Francisco / 11:00 New York / 16:00 London / 17:00 Paris / 20:30 Delhi / 23:00 Beijing / 00:00 Tokyo.</p>
<p>Speakers are:</p>
<ul>
<li><p><strong>Ying Gu</strong><br />
Snezhana Oliferenko Lab, King’s College London<br />
“Peroxisomal compartmentalization of amino acid biosynthesis reactions imposes an upper limit on compartment size”</p></li>
<li><p><strong>Yannis Reignier</strong><br />
Nicolas Minc lab, Institut Jacques Monod - CNRS, Paris<br />
“Mechanism of mechanosensation and repair in fission yeast cell wall.”</p></li>
</ul>
<p><a href="https://unige.zoom.us/j/69436207207?pwd=MlAIMX1pq4Kl4liXVcopH4MxXOo8C2.1">Zoom Meeting</a><br />
Meeting ID: 694 3620 7207</p>
<p>See <a href="https://lists.cam.ac.uk/sympa/info/ucam-pombelist">pombelist</a> for the meeting passcode</p>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/PombeTalks32px.png"/>
<h4 id="pombetalks-season-5-starting-september-18th"><strong>pombeTalks Season 5 starting September 18th</strong></h4>
<p><em>2024-08-29</em></p>
<p>Season 5 of <a href="https://evonuclab.org/pombetalks">pombeTalks</a> will start in September.</p>
<p>Save the dates:</p>
<ul>
<li>September 18th 2024 5pm CET</li>
<li>October 16th 2024 at 5pm CET</li>
<li>November 20th 2024 at 9am CET</li>
<li>December 18th 2024 at 5pm CET</li>
<li>January 15th 2025 at 5pm CET</li>
<li>February 19th 2025 at 9am CET</li>
</ul>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/pombase-logo-32x32px.png"/>
<h4 id="new-human-and-s.-cerevisiae-orthologs"><strong>New human and <em>S. cerevisiae</em> orthologs</strong></h4>
<p><em>2024-08-13</em></p>
<p>We have recently assigned new orthologs utilising <a href="https://xfam.wordpress.com/2024/05/31/pfam-n-version-3-enhancing-pfam-coverage-of-uniprot-with-computer-vision-deep-learning-techniques/">Pfam-N</a> protein family extensions, <a href="https://www.ebi.ac.uk/Tools/hmmer/search/jackhmmer">JackHMMER</a> iterative searches, and <a href="https://search.foldseek.com/search">FoldSeek</a> structural alignments.</p>
<p>New orthologs:<br />
We have identified orthologs of human <a routerLink="/gene/SPAP27G11.08c">MEIOB</a>, <a routerLink="/gene/SPCC16C4.04">PEX26</a>, <a routerLink="/gene/SPBC19G7.18c">OCIAD1 and OCIAD2</a>, <a routerLink="/gene/SPAC2C4.10c">SIKE1 and FGFR1OP2</a>, <a routerLink="/gene/SPCC1393.11">NGRN</a>, <a routerLink="/gene/SPAC4G9.11c">TFAM</a>, <a routerLink="/gene/SPAC15A10.17">NDUFA3</a>, <a routerLink="/gene/SPCC16C4.04">PEX26 (Sc PEX15)</a>, <a routerLink="/gene/SPAC1805.10">CCZ1</a>.<br />
Plus orthologs of <em>S. cerevisiae</em> <a routerLink="/gene/SPBC16A3.03c">MNE1</a>, <a routerLink="/gene/SPBC11C11.06c">OM14</a>, <a routerLink="/gene/SPAC57A7.15c">PEX22</a>, and <a routerLink="/gene/SPAC23H4.16c">CAF130</a>.</p>
<p>We have also assigned other sequence orphans to known protein families. See <a href="https://lists.cam.ac.uk/sympa/arc/ucam-pombelist/2024-08/msg00000.html">this pombelist post</a> for details.</p>
<p>These additions bring the number of proteins with human orthologs to <a routerLink="/results/from/id/8335a7b3-5105-4414-9743-bb6422692858">3641 (72%)</a>, and the number with S. cerevisiae orthologs to <a routerLink="/results/from/id/12a88b3a-9bab-4eae-a7e0-bb137b55d851">4006 (79%)</a>.</p>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/pombase-logo-32x32px.png"/>
<h4 id="unknowns-coverage-update"><strong>“Unknowns” coverage update</strong></h4>
<p><em>2024-07-10</em></p>
<p>In our 2019 work, <a href="https://royalsocietypublishing.org/doi/10.1098/rsob.180241">“Hidden in plain sight: What Remains to be discovered in the eukaryotic proteome?”</a>, we demonstrated that <em>S. pombe</em> had a higher GO biological process (BP) annotation coverage (86.7%) than both <em>S. cerevisiae</em> (83.5%) and human (82.8%).</p>
<p>Of the model eukaryotes S. pombe has:</p>
<ol type="1">
<li>The highest percentage known coverage</li>
<li>The fewest phylum-specific proteins</li>
<li>The smallest proteome (despite a higher number of genes conserved in metazoa than S. cerevisiae)</li>
<li>Dedicated efforts to uncover unknown functions</li>
</ol>
<p>and is uniquely positioned to be the first eukaryote for which we know the process or pathway for every protein</p>
<p>The trend we identified continues to hold (<em>S. pombe</em> “knowns” have increased to 86.9% in 2024). The coverage is confirmed by the <a href="https://genomeannotation.rheelab.org/#!/overview">Rhee lab GO overview</a>:</p>
<p><a href="assets/newsfeed/Rhee_lab_unknowns_coverage.png"><img src="assets/newsfeed/Rhee_lab_unknowns_coverage.png" title="Rhee lab unknowns coverage" class="screenshot" style="width:100.0%" alt="Rhee lab unknowns coverage" /></a></p>
<p>Image citation: <a href="https://doi.org/10.1002/pld3.499">Xue, B., &amp; Rhee, S. Y. (2023). Status of genome function annotation in model organisms and crops. Plant Direct, 7(7), e499.</a></p>
<p>Related links:</p>
<ul>
<li><a href="https://doi.org/10.1093/genetics/iyae007">Rutherford et al 2024</a> explains PomBase’s choice of metric for assessing knowledge breadth</li>
<li><a routerLink="/status/gene-characterisation">Protein coding gene characterization status at PomBase</a></li>
</ul>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/rna_central_icon.png"/>
<h4 id="rna-secondary-structure-predictions-on-gene-pages"><strong>RNA secondary structure predictions on gene pages</strong></h4>
<p><em>2024-07-03</em></p>
<p>We have added RNA secondary structure predictions from <a href="https://rnacentral.org/r2dt">R2DT (RNAcentral)</a> to the gene pages.</p>
<p>When a prediction is available an option will appear in the summary section of the RNA pages. See <a routerLink="/gene/SPRRNA.03">the SPRRNA.03 gene page</a> for an example.</p>
<p><a href="assets/newsfeed/rna_structure_SPRRNA.03_page.png"><img src="assets/newsfeed/rna_structure_SPRRNA.03_page.png" title="RNA structure - SPRRNA.03 gene page" class="screenshot" style="width:100.0%" alt="RNA structure - SPRRNA.03 gene page" /></a></p>
<p>You can retrieve the list of all RNA genes with secondary structure predictions using the “Genes with RNA secondary structure predictions” query under “Commonly used queries” in the <a routerLink="/query">PomBase Advanced Search</a>.</p>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/human_disease.png"/>
<h4 id="human-disease-association-changes---monarch"><strong>Human disease association changes - Monarch</strong></h4>
<p><em>2024-06-21</em></p>
<p>We have changed our major source of human disease associations from <a href="https://www.malacards.org/">MalaCards</a> to <a href="https://monarchinitiative.org/">Monarch</a>.</p>
<p>This change has:</p>
<ol type="1">
<li>Increased the number of fission yeast genes with disease associations from 1463 to 1551, made the associations more accurate and up to date</li>
<li>Allowed us to automate the inclusion of new disease gene imports from Monarch based on our ortholog mappings</li>
<li>Enabled us to add the qualifiers “causal” or “correlated with” (for multi-genic disorders) to the disease association (you can see this on the gene pages under “show details”)</li>
</ol>
<p>Disease associations in PomBase:</p>
<ul>
<li><a routerLink="/term/MONDO:0700096">Browse the new disease list</a></li>
<li><a routerLink="/browse-curation/disease-slim">Overview of disease associations (disease slim)</a></li>
</ul>
<p>If you have knowledge of a specific disease class or notice any issues with the ontology you can <a href="https://github.com/monarch-initiative/mondo/issues/">report this to Monarch</a></p>
<p>The Monarch initiative also host our fission yeast phenotype associations in their <a href="https://monarchinitiative.org/">knowledge graph</a>.</p>
<p>Related items: <a href="https://monarchinit.medium.com/pombase-chooses-monarch-as-source-of-human-disease-gene-associations-e53c6d218dce">Monarch Initiative blog post</a></p>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/interpro_32px.png"/>
<h4 id="pombase-now-includes-data-from-interpro-v100.0"><strong>PomBase now includes data from InterPro v100.0</strong></h4>
<p><em>2024-06-14</em></p>
<p>PomBase now uses <a href="https://www.ebi.ac.uk/interpro/">InterPro</a> <a href="https://www.ebi.ac.uk/interpro/release_notes/">release 100.0</a>.</p>
<p>Features include:</p>
<ul>
<li>The addition of 570 InterPro entries.</li>
<li>An update to Pfam [37.0].</li>
<li>Integration of 582 new methods from the NCBIfam (26), Pfam (545), PANTHER (3), CDD (8) databases.</li>
</ul>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/pombase-logo-32x32px.png"/>
<h4 id="new-database-update-publication-from-pombase"><strong>New database update publication from PomBase</strong></h4>
<p><em>2024-02-22</em></p>
<p>The fission yeast 2024 database update “PomBase: a Global Core Biodata Resource—growth, collaboration, and sustainability” has been <a href="https://doi.org/10.1093/genetics/iyae007">published in <em>Genetics</em></a>. This update describes PomBase’s continuing growth and improvement over the past two years and showcases the depth of existing connections both within the biocuration ecosystem and between PomBase and its user community.</p>
<p>We discuss ways to track knowledge coverage including the tracking of unknown proteins, and the use of slimsets. We describe how we provide value by data and software sharing and how we have reused software provided by others in our new views. We provide author-led curation updates, describe contributions to global standards, future plans to support machine learning with high quality curated data and more.</p>
<p><a href="https://doi.org/10.1093/genetics/iyae007">Rutherford <em>et al.</em>, <em>Genetics</em>, iyae007</a></p>
<p><a href="assets/newsfeed/genetics_2024_paper_fig_5.png"><img src="assets/newsfeed/genetics_2024_paper_fig_5.png" title="Data dissemination from PomBase to other resources" class="screenshot" loading="lazy" style="width:100.0%" alt="“Fig 5 Data dissemination from PomBase to other resources”" /></a></p>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/pombase-logo-32x32px.png"/>
<h4 id="viewing-protein-modifications"><strong>Viewing protein modifications</strong></h4>
<p><em>2024-02-08</em></p>
<p>Modified amino acids in the protein feature viewer are now coloured by modification type. See the <a routerLink="/gene_protein_features/SPAC1834.04">histone H3.1 gene page</a> for an example.</p>
<p><a href="assets/newsfeed/gene-hht1-protein-feature-viewer-page.png"><img src="assets/newsfeed/gene-hht1-protein-feature-viewer-page.png" class="screenshot" loading="lazy" style="width:100.0%" alt="Protein feature view for hht1" /></a></p>
<p><a href="assets/newsfeed/protein-feature-modification-key.png"><img src="assets/newsfeed/protein-feature-modification-key.png" class="screenshot" loading="lazy" style="width:100.0%" alt="Protein feature view modification colour key" /></a></p>
<p>Visit the <a routerLink="/documentation/gene-page-protein-features-widget">protein feature view documentation page</a> for more information.</p>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/pombase-logo-32x32px.png"/>
<h4 id="access-target-genes-of-individual-gene-products"><strong>Access target genes of individual gene products</strong></h4>
<p><em>2024-02-07</em></p>
<p>It is now possible to send lists of annotated molecular function substrates to the advanced search from gene pages:</p>
<p><a href="assets/newsfeed/cdc2-substrates-to-advanced-search.png"><img src="assets/newsfeed/cdc2-substrates-to-advanced-search.png" class="screenshot" loading="lazy" style="width:100.0%" alt="cdc2 - send target genes to the Advanced Search" /></a></p>
<p>Lists of annotated molecular function substrates of individual genes (i.e protein kinase or transcription factor target genes) can now be sent directly to the Advanced Search. You can send complete lists, or pre-filtered by cell cycle phase.</p>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/elixir_uk_thumb.png"/>
<h4 id="pombase-joins-elixir-core-data-resources"><strong>PomBase joins ELIXIR Core Data Resources</strong></h4>
<p><em>2023-12-14</em></p>
<p>PomBase is the first model organism database (MOD) to be selected as an <a href="https://elixir-europe.org">ELIXIR</a> <a href="https://elixir-europe.org/platforms/data/core-data-resources">Core data resource (CDR)</a>. We were evaluated across a long list of indicators including scientific focus and quality, governance, and quality of service. ELIXIR CDRs are European life science data resources that are important to biological and biomedical research and long-term preservation of biological data. This will provide assurance to our funders and users that PomBase satisfies the many criteria required to considered a key part of the European bioinformatics infrastructure. <a href="https://elixir-europe.org">ELIXIR</a> is an intergovernmental organisation that brings together life science resources from across Europe, it currently includes <a href="https://elixir-europe.org/about-us/who-we-are">22 member countries</a>, bringing together over 240 research organisations. The goal of
ELIXIR is to coordinate these resources so that they form a single infrastructure.</p>
<p><a href="assets/newsfeed/elixir_pombase_cdr_announcement.png"><img src="assets/newsfeed/elixir_pombase_cdr_announcement.png" title="ELIXIR CDR announcement" class="screenshot" loading="lazy" style="width:100.0%" alt="ELIXIR CDR announcement" /></a></p>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/pombase-logo-32x32px.png"/>
<h4 id="new-documentation-and-videos-for-canto"><strong>New documentation and videos for Canto</strong></h4>
<p><em>2023-10-11</em></p>
<p>We have published <a href="https://pombase.github.io/canto_tutorial/">a new website</a> with videos and documentation for <a href="https://curation.pombase.org/">Canto</a>, our community curation tool. The website includes short step-by-step text and video guides on how to make annotations in Canto.</p>
<ul>
<li><a href="https://pombase.github.io/canto_tutorial/">New Canto documentation front page</a></li>
<li><a href="https://www.youtube.com/@PomBase">PomBase YouTube channel</a></li>
</ul>
<p>The work on this website was supported by the ELIXIR-UK: FAIR Data Stewardship training UKRI award (MR/V038966/1) to Manuel Lera Ramírez.</p>
<p><a href="assets/newsfeed/new_canto_video_docs.png"><img src="assets/newsfeed/new_canto_video_docs.png" title="New Canto tutorials video list" class="screenshot" loading="lazy" style="width:100.0%" alt="New Canto tutorials video list" /></a></p>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/pombase-logo-32x32px.png"/>
<h4 id="revised-fission-yeast-nomenclature-for-genes-and-alleles"><strong>Revised fission yeast nomenclature for genes and alleles</strong></h4>
<p><em>2023-09-30</em></p>
<p>The improved nomenclature guidelines for fission yeast clade genes and alleles have been <a href="https://academic.oup.com/genetics/advance-article/doi/10.1093/genetics/iyad143/7283026">published in Genetics GSA</a>. This update introduces nomenclature guidelines for noncoding RNA genes, a significant update to the allele and genotype nomenclature guidelines originally published in 1987. We took several steps to improve machine readability that will benefit the artificial intelligence/machine learning communities, and our own community curation program.</p>
<p><a href="https://doi.org/10.1093/genetics/iyad143">Lera-Ramírez <em>et al.</em> 2023, Revised fission yeast gene and allele nomenclature guidelines for machine readability, Genetics, 2023;, iyad143</a></p>
<p><a href="assets/newsfeed/nomenclature_paper_for_news_big.png"><img src="assets/newsfeed/nomenclature_paper_for_news.png" title="Revised fission yeast gene and allele nomenclature guidelines for machine readability" class="screenshot" loading="lazy" style="width:100.0%" alt="“Revised fission yeast gene and allele nomenclature guidelines for machine readability”" /></a></p>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/pombase-logo-32x32px.png"/>
<h4 id="accessing-the-list-of-genes-from-a-paper"><strong>Accessing the list of genes from a paper</strong></h4>
<p><em>2023-09-20</em></p>
<p>It’s now possible to view the list of genes associated with a paper, using a new link on the publication pages. The gene list can then optionally be sent to the Advanced Search.</p>
<p><a href="assets/newsfeed/genes-list-from-ref-page.png"><img src="assets/newsfeed/genes-list-from-ref-page.png" title="Publication page, highlightling the new genes link" class="screenshot" loading="lazy" style="width:100.0%" alt="Publication page, highlightling the new genes link" /></a></p>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/pombase-logo-32x32px.png"/>
<h4 id="protein-feature-viewer-added-to-gene-pages"><strong>Protein feature viewer added to gene pages</strong></h4>
<p><em>2023-07-27</em></p>
<p>PomBase gene pages now include a protein feature widget. This tool shows protein features in the context of amino acid sequence. It includes:</p>
<ul>
<li>amino acid substitution positions</li>
<li>Pfam domains</li>
<li>protein modifications</li>
<li>protein properties: low complexity regions, disordered regions, coiled coil regions and predicted trans-membrane domains</li>
</ul>
<p>Visit the <a routerLink="/gene/SPAC20G8.05c">cdc15 gene page</a> to see an example.</p>
<p>Hover over features for more information, such as allele descriptions and Pfam domain IDs.</p>
<p><a href="assets/gene-protein-feature-viewer-widget-cdc15.png"><img src="assets/gene-protein-feature-viewer-widget-cdc15.png" title="Protein feature viewer widget - cdc15 gene page" class="screenshot" style="width:100.0%" alt="Protein feature viewer widget - cdc15 gene page" /></a></p>
<p>Follow the “View all protein features …” link for a detailed view on the dedicated protein features page that includes:</p>
<ul>
<li>details of the individual amino acid substitution allele changes</li>
<li>partial amino acid deletions</li>
</ul>
<p><a href="assets/gene-protein-feature-viewer-page-cdc15.png"><img src="assets/gene-protein-feature-viewer-page-cdc15.png" title="Protein feature viewer details page for cdc15" class="screenshot" style="width:100.0%" alt="Protein feature viewer details page for cdc15" /></a></p>
<p>Thanks to the team at <a href="https://www.rcsb.org/">RCSB PDB</a> for providing the <a href="https://github.com/rcsb/rcsb-saguaro">Open Source software</a> used to implement this feature.</p>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/pombase-logo-32x32px.png"/>
<h4 id="reaction-diagrams-on-go-molecular-function-term-pages"><strong>Reaction diagrams on GO molecular function term pages</strong></h4>
<p><em>2023-06-19</em></p>
<p>Where available, we now show the reaction diagram from <a href="https://www.rhea-db.org/">Rhea</a> on GO molecular function term pages. This feature is possible thanks to the great work of <a href="https://www.rhea-db.org/">Rhea</a>.</p>
<p>See the <a routerLink="/term/GO:0003849">3-deoxy-7-phosphoheptulonate synthase activity (GO:0003849) term page</a> for an example.</p>
<p><a href="assets/newsfeed/rhea-reaction-example.png"><img src="assets/newsfeed/rhea-reaction-example.png" class="screenshot" loading="lazy" style="width:100.0%" /></a></p>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/pombase-logo-32x32px.png"/>
<h4 id="curation-update---nonsense-mutation-merged-into-partial-amino-acid-deletion"><strong>Curation update - “nonsense mutation” merged into “partial amino acid deletion”</strong></h4>
<p><em>2023-04-26</em></p>
<p>We have decided to merge the allele type “nonsense mutation” into “partial amino acid deletion”. This has mainly been driven by the fact that allele types that combine different variants require new types, such as “amino_acid_deletion_and_mutation”, “amino_acid_insertion_and_deletion”, etc. Otherwise, we would have ended up with many more types, and at the gene product level (which is what we describe in PomBase in phenotype interactions), both truncations are equivalent. In the next update, this allele type will not be available in Canto.</p>
<p>In any case, even if two alleles produce the same truncation, such as ase1-D13* or ase1Δ(13-731), they would still have separate entries in PomBase, and they may have different phenotypes. We are only assigning them the same allele type.</p>
<p>If for your analysis you need to make a distinction between the two using our allele dataset <a routerLink="/downloads/phenotype-annotations">allele dataset</a>, you can always check the “Allele description” field for the presence of the “*” character to tell whether an allele includes a nonsense mutation.</p>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/protein-structure.png"/>
<h4 id="experimental-structures-from-pdb-on-gene-pages"><strong>Experimental structures from PDB on gene pages</strong></h4>
<p><em>2023-02-22</em></p>
<p>The experimental protein structures from <a href="https://www.ebi.ac.uk/pdbe/">PDB</a> are now embedded on the PomBase gene pages using <a href="https://molstar.org/">Mol*</a>. For example: <a routerLink="/gene/SPCC285.12">lsm7/SPCC285.12 gene page</a></p>
<p>If you select the “PDB structures” view on a gene page, experimental structures will set as your default. AlphaFold predictions will be shown for genes where an experimental structure are not available.</p>
<p>We now also display the structures on the associated publication page. For example: <a routerLink="/reference/PMID:31010807">PMID:31010807 Garg <em>et al.</em></a></p>
<p>To help locate proteins with experimental protein structures (currently 375), we have added a new query option to the “<a routerLink="/query">Advanced search</a>”, currently under “commonly used queries”: “<a routerLink="/results/from/id/6e8401aa-bab8-48fc-bbb7-c85d13ea10a2">Proteins with PDB structures</a>”</p>
<p><a href="assets/newsfeed/pdb_structures_lsm7_small.png"><img src="assets/newsfeed/pdb_structures_lsm7_small.png" class="screenshot" loading="lazy" style="width:100.0%" alt="PDB structures on the lsm7 gene page" /></a></p>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/protein-structure.png"/>
<h4 id="alphafold-protein-structure-on-gene-pages"><strong>AlphaFold protein structure on gene pages</strong></h4>
<p><em>2023-02-02</em></p>
<p>AlphaFold protein structure are now embedded on the PomBase gene pages. We hope to embed the experimental structures from PDB in the near future.</p>
<p><a href="assets/newsfeed/alphafold-news-item-example-1.png"><img src="assets/newsfeed/alphafold-news-item-example-1.png" class="screenshot" loading="lazy" style="width:100.0%" alt="Example from the mvp1 gene page" /></a></p>

</div>
<div class="news-item">
<img loading="lazy" class="newsfeed-thumbnail" src="/assets/newsfeed/pombase-logo-32x32px.png"/>
<h4 id="revised-canonical-5-utrs"><strong>Revised canonical 5’ UTRs</strong></h4>
<p><em>2023-01-24</em></p>
<p>We have revised the curated 5’UTRs using Transcription Start Sites (TSS) data (in vegetative growth/ minimal media) from the Cap Analysis of Gene Expression (CAGE) data provided by <a routerLink="/reference/PMID:30566651">Thodberg <em>et al</em></a>. All new gene structures were manually reviewed, around ~80 protein features had N-terminal coordinate revisions to align with TSS data.</p>

</div>

<div id="archive-link"><a routerLink="/news/">News archive ...</a></div>
</div>

